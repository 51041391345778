<template>
  <div class="my-breadcrumb">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "breadcrumb",
};
</script>

<style lang="scss">
.my-breadcrumb {
  width: 1100px;
  display: block;
  /* border-bottom: 1px solid #efefef; */
  margin: 20px auto;
  color: #8d8d8d;
  span {
    color: black;
  }
}

@media screen and (max-width: 768px) {
  .my-breadcrumb {
    display: none;
  }
}
</style>