import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { fetchClassifyId, fetchListByClassifyId } from '@/utils/home';
import store from '@/store'; // 假设你使用Vuex

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    redirect: "/home",
    component: () => import('../views/Layout/index'),
    children: [
      {
        path: 'home',
        name: '中国中医药研究促进会首页',
        component: () => import('../views/HomeView'),
      },
      {
        path: '/about',
        name: '学会概况',
        component: () => import('../views/AboutView')
      },
      {
        path: '/policies',
        name: '党建政策',
        component: () => import('../views/Policies')
      },
      {
        path: '/technology',
        name: '科技之窗',
        component: () => import('../views/Technology')
      },
      {
        path: '/service',
        name: '办事服务',
        component: () => import('../views/Service')
      },
      {
        path: '/declaration',
        name: '活动会议申报',
        component: () => import('../views/details/Declaration')
      },
      {
        path: '/decDetail',
        name: '活动会议申报',
        component: () => import('../views/details/DecDetail')
      },
      {
        path: '/improve',
        name: '产业促进',
        component: () => import('../views/productimprove')
      },
      {
        path: '/academic',
        name: '学术交流',
        component: () => import('../views/Academic')
      }, {
        path: '/download',
        name: '下载专区',
        component: () => import('../views/Download')
      }, {
        path: '/information',
        name: '信息查询',
        component: () => import('../views/Information')
      },
      {
        path: '/inforDetail',
        name: '信息查询',
        component: () => import('../views/details/InforDetail')
      },
      {
        path: '/supervise',
        name: '监督举报',
        component: () => import('../views/Supervise')
      }, {
        path: '/content',
        name: '图文内容',
        component: () => import('../views/details/Content')
      },
      {
        path: '/branchContent',
        name: '图文内容',
        component: () => import('../views/details/branchContent')
      },
      {
        path: '/contentList',
        name: '内容列表',
        component: () => import('../views/details/ContentList')
      },
      {
        path: '/branchList',
        name: '内容列表',
        component: () => import('../views/details/branchList')
      },
      {
        path: '/videoContent',
        name: '视频内容',
        component: () => import('../views/details/VideoContent')
      },
      {
        path: '/functional',
        name: '职能部门',
        component: () => import('../views/details/Functional')
      },
      {
        path: '/searchList',
        name: '搜索列表',
        component: () => import('../views/details/SearchList')
      },
      {
        path: '/setproject',
        name: '课题立项',
        component: () => import('../views/details/Setproject')
      },
      {
        path: '/situation',
        name: '学会概况',
        component: () => import('../views/details/Situation')
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//   // 在每次路由切换前动态设置页面标题
//   document.title = to.name || '中国中医药研究促进会';
//   next();
// });

router.beforeEach(async (to, from, next) => {
  document.title = to.name || '中国中医药研究促进会';

  const categoryNameMap = {
    '/home': '首页',
    '/about': '学会概况',
    '/policies': '党建政策',
    '/technology': '科技之窗',
    '/academic': '学术交流',
    '/improve': '产业促进',
    // '/download': '下载专区',
    '/supervise': '监督举报',
    '/setproject': '课题立项'
  };

  const categoryName = categoryNameMap[to.path];

  if (categoryName) {
    try {
      await store.dispatch('fetchAndStoreData', { categoryName })
      next();

    } catch (error) {
      console.error(`Error during route guard for ${to.path}:`, error);
      next(false); // 出现错误时取消导航
    }
  } else {
    next(); // 如果路径不匹配，直接进入下一个路由
  }
});


export default router
