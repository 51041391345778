import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/normalize.css'
import '@/styles/index.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import breadcrumb from "./components/breadcrumb.vue"
import j from "./components/j.vue"
// import './font/font.css'

Vue.use(ElementUI);

Vue.config.productionTip = false

Vue.component('breadcrumb', breadcrumb)
Vue.component('j', j)

// 批量导入css
const requireContext = require.context('./styles/fromWeb', true, /\.css$/);
const cssFiles = requireContext.keys();
cssFiles.forEach(file => {
  requireContext(file);
});


// import VConsole from 'vconsole';

// const vConsole = new VConsole();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
