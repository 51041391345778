<template>
  <div class="j" @click="navigateToUrl">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    url: String, // 声明接受名为 'url' 的字符串参数
  },
  name: "j",
  methods: {
    navigateToUrl() {
      // 点击组件时导航到指定的 URL
      if (this.url) {
        window.location.href = this.url;
      }
    },
  },
};
</script>

