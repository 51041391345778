<template>
  <section id="hy-body">
    <div class="body-content container">
      <div class="header" id="header"></div>
      <div style="clear: both"></div>
      <div style="height: 360px; overflow: hidden; position: relative">
        <!--   轮播   -->
        <div id="slide_show_content">
          <div id="slide_show">
            <div id="photo_box" style="right: 0px">
              <el-carousel
                ref="carousel"
                height="360px"
                @change="carouselChange"
                arrow="never"
                :interval="5000"
              >
                <el-carousel-item v-for="item in carouselList" :key="item.id">
                  <div class="photo" style="cursor: pointer">
                    <img :src="item.img" @click="jumpToContent(item.id)" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div
            id="slide_show_footer"
            style="
              position: absolute;
              z-index: 999;

              height: 50px;
              opacity: 0.9;
            "
          >
            <div id="text_box">
              <template v-for="(item, index) in carouselList">
                <div
                  class="text"
                  :class="{ selected: index === carouselIndex }"
                  :key="item.id"
                >
                  {{ item.text }}
                </div>
              </template>
            </div>

            <div id="point_box">
              <template v-for="(item, index) in carouselList">
                <div
                  class="point"
                  :class="{ selected: index === carouselIndex }"
                  :key="item.id"
                  @click="carouselClick(index)"
                ></div>
              </template>
            </div>
          </div>
        </div>

        <!-- 公告栏 -->
        <div class="hy_notice hy_notice_right j_notice isshow2">
          <div class="title">
            <span
              v-for="(item, index) in headlines"
              :key="index"
              :class="{ active: isClickIndex === index }"
              @click="changeIndex(index, item.id, item.name)"
              >{{ item.name }}</span
            >
          </div>
          <div
            v-if="headAnnoun.length > 5"
            @click="jumpToContentList(headMore)"
            style="margin-top: 20px; text-align: right"
            class="more11"
          >
            更多>>>
          </div>
          <div class="content">
            <div
              class="row"
              v-for="(item, index) in headAnnoun"
              :key="index"
              @click="jumpToContent(item.id)"
            >
              <div class="point"></div>
              <div class="text" :title="item.title">
                {{ item.title }}
              </div>

              <div class="time">[{{ item.createTime.split(" ")[0] }}]</div>
            </div>

            <!-- <div
              class="more"
              v-if="headAnnoun.length > 5"
              @click="jumpToContentList(headMore)"
            >
              更多>>>
            </div> -->

            <div style="clear: both"></div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px; width: 100%; height: auto" class="isshow">
        <!-- 公告栏 小屏展示版-->
        <div class="hy_notice hy_notice_right j_notice">
          <div class="title">
            <span
              v-for="(item, index) in headlines"
              :key="index"
              :class="{ active: isClickIndex === index }"
              @click="changeIndex(index, item.id, item.name)"
              >{{ item.name }}</span
            >
          </div>
          <div
            v-if="headAnnoun.length > 5"
            @click="jumpToContentList(headMore)"
            style="margin-top: 20px; text-align: right"
            class="more11"
          >
            更多>>>
          </div>
          <div class="content">
            <div
              class="row"
              v-for="(item, index) in headAnnoun"
              :key="index"
              @click="jumpToContent(item.id)"
            >
              <div class="point"></div>
              <div class="text" :title="item.title">
                {{ item.title }}
              </div>

              <div class="time">[{{ item.createTime.split(" ")[0] }}]</div>
            </div>

            <!-- <div
              class="more"
              v-if="headAnnoun.length > 5"
              @click="jumpToContentList(headMore)"
            >
              更多>>>
            </div> -->

            <div style="clear: both"></div>
          </div>
        </div>
      </div>
      <div style="clear: both"></div>

      <div style="margin-top: 20px; width: 100%; height: auto">
        <!-- 党政建设 -->
        <div class="hy_notice hy_notice_left j_notice">
          <div class="title">
            <span
              v-for="(item, index) in policiesArr"
              :key="index"
              :class="{ active: isPoliciesIndex === index }"
              @click="changePolicies(index, item.id, item.name)"
              >{{ item.name }}</span
            >
          </div>
          <div
            v-if="policyList2.length >= 10"
            @click="jumpTo(isPoliciesIndex)"
            style="margin-top: 20px; text-align: right"
            class="more11"
          >
            更多>>>
          </div>
          <div class="content">
            <div class="row" v-for="(item, index) in policyList2" :key="index">
              <div class="point"></div>
              <div class="text" style="width: 520px" :title="item.title">
                <a @click="jumpToContent(item.id)">{{ item.title }}</a>
              </div>
              <div class="time">{{ item.createTime.split(" ")[0] }}</div>
            </div>
            <!-- <div
              class="more"
              v-if="policyList2.length >= 10"
              @click="jumpTo(isPoliciesIndex)"
            >
              更多>>>
            </div> -->
            <div style="clear: both"></div>
          </div>
        </div>

        <!-- 学会动态 -->
        <div class="hy_notice hy_notice_right j_notice">
          <div class="title">
            <span
              v-for="(item, index) in learnArr"
              :key="index"
              :class="{ active: isLearnIndex === index }"
              @click="changeLearn(index, item.id, item.name)"
              >{{ item.name }}</span
            >
            <!-- <span class="text">学会动态</span> -->
          </div>
          <div
            v-if="learnoticeList.length > 5"
            @click="jumpToContentList(learnMore)"
            style="margin-top: 20px; text-align: right"
            class="more11"
          >
            更多>>>
          </div>
          <div class="content">
            <div
              class="row"
              v-for="(item, index) in learnoticeList"
              :key="index"
            >
              <div class="point"></div>
              <div class="text" :title="item.title">
                <a @click="jumpToContent(item.id)">{{ item.title }}</a>
              </div>
              <div class="time">{{ item.createTime.split(" ")[0] }}</div>
            </div>
            <!-- <div
              class="more"
              v-if="learnoticeList.length > 5"
              @click="jumpToContentList(learnMore)"
            >
              更多>>>
            </div> -->

            <div style="clear: both"></div>
          </div>
        </div>
      </div>

      <div style="clear: both"></div>
      <!--    跑马灯     -->

      <div
        style="margin-top: 20px; width: 100%; height: auto"
        v-if="showcarousel"
      >
        <div
          v-if="h_carouselList.length > 0"
          class="h_carousel"
          id="h_carousel"
          changewidth="260"
          width="1040px"
          photostyle="width: 250px, margin: 5px"
        >
          <div class="h_stage">
            <div class="h_photo_box" style="right: 0px" @click="jumpToVedio">
              <div
                class="h_photo"
                v-for="item in h_carouselList"
                :key="item.id"
              >
                <img :src="item.img" :id="item.id" />
                <div class="h_time">{{ item.time }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 20px; width: 100%; height: auto">
        <!-- 党政建设 -->
        <div class="hy_notice hy_notice_left j_notice">
          <div class="title">
            <a @click="jumpToTechnology" style="text-decoration: none">
              <span class="text" style="float: left">科技之窗</span></a
            >
          </div>
          <div class="content">
            <div
              class="row"
              v-for="(item, index) in technologyList"
              :key="index"
            >
              <div class="point"></div>
              <div class="text" style="width: 520px" :title="item.title">
                <a @click="jumpToContent(item.id)">{{ item.title }}</a>
              </div>
              <div class="time">{{ item.createTime.split(" ")[0] }}</div>
            </div>

            <div style="clear: both"></div>
          </div>
        </div>

        <!-- 课题立项 -->
        <div class="hy_notice hy_notice_right j_notice">
          <div class="title">
            <a @click="jumpToSetproject" style="text-decoration: none"
              ><span class="text" style="float: left">课题立项</span></a
            >
          </div>
          <div class="content">
            <div class="row" v-for="(item, index) in projectList" :key="index">
              <div class="point"></div>
              <a @click="jumpToContent(item.id)">
                <div class="text" :title="item.title">
                  {{ item.title }}
                </div>
              </a>
              <div class="time">[{{ formatDate(item.createTime) }}]</div>
            </div>

            <div style="clear: both"></div>
          </div>
        </div>
      </div>

      <div style="clear: both"></div>

      <div class="index-center-banner">
        <a @click="jumpToNewContentList" target="_blank">
          <img
            src="../assets/images/20-14-5808801740841533.png"
            style="cursor: pointer"
        /></a>
      </div>

      <div style="width: 100%; height: auto">
        <div class="storey-children hy_notice j_light">
          <div class="title">
            <span
              class="text"
              style="cursor: text; float: left; padding-bottom: 44px"
              >光明中医</span
            >
          </div>
          <div class="news-container" style="margin-top: 30px">
            <div class="news-build-list one"></div>
            <!-- 左边一列 -->
            <div class="news-build-list two">
              <div class="content-list">
                <p v-for="(item, index) in medicineLeft" :key="index">
                  <span class="text over_omission"
                    ><b>.</b
                    ><a @click="jumpToContent(item.id)">
                      {{ item.title }}</a
                    ></span
                  >&nbsp;&nbsp;<span class="time"
                    >[{{ formatDate(item.createTime) }}]</span
                  >
                </p>
              </div>
            </div>
            <div class="news-build-list center"></div>
            <!-- 右边一列 -->
            <div class="news-build-list three">
              <div class="content-list">
                <p v-for="(item, index) in medicineRight" :key="index">
                  <span class="text over_omission"
                    ><b>.</b
                    ><a @click="jumpToContent(item.id)">
                      {{ item.title }}</a
                    ></span
                  >&nbsp;&nbsp;<span class="time"
                    >[{{ formatDate(item.createTime) }}]</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="clear: both"></div>

      <!-- 图片轮播 -->
      <div
        style="margin-top: 20px; width: 100%; height: auto"
        v-if="showcarousel"
      >
        <div
          class="h_carousel"
          id="h_carousel2"
          changewidth="260"
          v-if="carouselPic.length > 0"
        >
          <div class="h_stage" style="height: 70px">
            <div
              class="h_photo_box"
              style="right: 0px"
              @click="jumpToContentPic"
            >
              <div class="h_photo" v-for="item in carouselPic" :key="item.id">
                <img :src="item.img" :id="item.id" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 20px; width: 100%; height: auto">
        <div class="hy_notice">
          <div class="title" style="cursor: context-menu">
            <span class="text" style="float: left; padding-bottom: 44px"
              >国开园</span
            >
          </div>

          <div class="content">
            <div class="col-md-4 col-xs-12 content-left" style="padding: 0">
              <img
                src="../assets/images/11-26-140016-1276678561.jpg"
                alt=""
                width="100%"
              />
            </div>
            <div class="col-md-8 col-xs-12 content-right">
              <p style="line-height: 35px">
                <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
                <span v-html="gardenlist[0].content"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import { getClassifyId } from "@/api/home";
// import "@/styles/index2.scss";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      // 头条信息标题
      headlines: [
        {
          name: "头条信息",
          id: 1,
        },
        {
          name: "公告声明",
          id: 2,
        },
      ],
      // 学会动态标题
      learnArr: [
        {
          name: "学会动态",
          id: 3,
        },
        {
          name: "通知公告",
          id: 4,
        },
      ],
      policiesArr: [
        // {
        //   name: "党建政策",
        //   id: 5,
        // },
        {
          name: "主题活动",
          id: 5,
        },
        {
          name: "分会动态",
          id: 6,
        },
      ],
      isPoliciesIndex: 0,
      isClickIndex: 0,
      isLearnIndex: 0,
      carouselIndex: 0,
      showcarousel: true,
      // 头部轮播
      carouselList: [
        // {
        //   id: 1,
        //   img: "http://www.cracm.org/publiccms/webfile/upload/2022/09-05/14-25-430550-808047471.jpg",
        //   text: "中医药国际交流合作发展报告启动发布仪式在服贸会举行",
        // },
        // {
        //   id: 2,
        //   img: "http://www.cracm.org/publiccms/webfile/upload/2021/12-31/14-35-140031209756344.png",
        //   text: "第六次会员代表大会",
        // },
        // {
        //   id: 3,
        //   img: "http://www.cracm.org/publiccms/webfile/upload/2023/12-07/11-11-300399311223395.jpg",
        //   text: "2023中国中医药科技大会 暨创新中医药高质量发展大会",
        // },
        // {
        //   id: 4,
        //   img: "http://www.cracm.org/publiccms/webfile/upload/2023/12-07/11-23-350589-534424177.jpg",
        //   text: "中国中医药研究促进会会长高武颁发证书",
        // },
      ],
      // 图片轮播
      carouselPic: [],

      // 图文
      pic: [],

      // 头条信息
      headlineList: [],

      // 公告声明
      announcementList: [],

      headAnnoun: [],

      // 党建政策
      policyList: [],

      //分会动态
      meeting: [],

      //主题活动
      mainactivity: [],

      // 学会动态
      learnList: [],

      // 通知公告
      noticeList: [],

      learnoticeList: [],

      policyList2: [],
      // 视频轮播
      h_carouselList: [
        // {
        //   img: "http://www.cracm.org/publiccms/webfile/upload/2022/01-04/15-56-290315-1675797442.png",
        //   id: 1,
        //   time: "30:00",
        // },
        // {
        //   img: "http://www.cracm.org/publiccms/webfile/upload/2021/11-01/11-47-570554-185623835.jpg",
        //   id: 2,
        //   time: "30:00",
        // },
        // {
        //   img: "http://www.cracm.org/publiccms/webfile/upload/2021/11-19/10-26-440232568322666.png",
        //   id: 3,
        //   time: "30:00",
        // },
        // {
        //   img: "http://www.cracm.org/publiccms/webfile/upload/2021/11-30/10-37-4100041731911357.png",
        //   id: 4,
        //   time: "30:00",
        // },
      ],

      // 科技之窗
      technologyList: [],

      // 课题立项
      projectList: [],

      // 光明中医
      medicineList: [],
      medicineLeft: [],
      medicineRight: [],

      // 国开园
      gardenlist: [],

      headMore: "头条信息",
      learnMore: "学会动态",
      policiesMore: "党建政策",
    };
  },
  created() {
    this.handleAllData(this.allPageData);
    // console.log(this.allPageData,'首页');
  },
  computed: {
    allPageData() {
      return this.$store.state.allPageData;
    },
  },

  mounted() {
    this.setPicCarouse();
    this.handleMedicine();
    this.headAnnoun = this.headlineList;
    (this.learnoticeList = this.learnList),
      (this.policyList2 = this.mainactivity),
      (this.gardenlist[0].content = this.gardenlist[0].content ?? "");
    window.addEventListener("visibilitychange", () => {
      console.log(document.hidden);
      // !document.hidden
      if (!document.hidden) {
        clearInterval(this.timer);
        // this.setPicCarouse();
        // this.showcarousel = false;
        // this.$nextTick(() => {
        //   this.showcarousel = true;
        //   this.setPicCarouse();
        // });
        // location.reload();
      } else {
        clearInterval(this.timer);
        // this.setPicCarouse(2);
      }
    });
  },

  methods: {
    handleAllData(newData) {
      // 定义一个映射关系，从名称到对应的列表
      const carouselArry = [];
      const picArry = [];
      const spArry = [];
      const listMapping = {
        头条信息: this.headlineList,
        公告声明: this.announcementList,
        学会动态: this.learnList,
        通知公告: this.noticeList,
        头部轮播: carouselArry,
        图片轮播: picArry,
        图文标题: this.pic,
        视频轮播: spArry,
        课题立项: this.projectList,
        光明中医: this.medicineList,
        国开园: this.gardenlist,
        分会动态: this.meeting,
        主题活动: this.mainactivity,
      };
      const policy = {
        党建政策: this.policyList,
        科技奖励: this.technologyList, //科技之窗，要用原来的科技奖励
      };

      // 遍历新数据，根据名称分类到对应的列表
      newData.forEach((ele) => {
        if (listMapping[ele.name] && Array.isArray(listMapping[ele.name])) {
          listMapping[ele.name].push(ele);
          console.log(listMapping[ele.name], ele.name, "111");
        } else if (
          policy[ele.parentName] &&
          Array.isArray(policy[ele.parentName])
        ) {
          policy[ele.parentName].push(ele);
          console.log(policy[ele.parentName], ele.parentName, "222");
        } else {
          // 如果ele.name在listMapping中找不到，可以在这里处理，比如打印日志
          console.log(`No list for "${ele.name}" found, skipping.`);
        }
      });
      this.carouselList = carouselArry.map((v) => {
        return {
          id: v.id,
          text: v.title,
          img: v.cover,
        };
      });
      console.log(this.carouselList, "头部轮播111");

      this.carouselPic = picArry.map((v) => {
        return {
          id: v.id,
          img: this.extractSrc(v.content),
        };
      });
      console.log(this.carouselPic, "图片轮播2222");

      this.h_carouselList = spArry.map((v) => {
        return {
          id: v.id,
          img: v.cover,
          time: v.duration,
        };
      });
      console.log(this.h_carouselList, "视频轮播3333");
    },

    extractSrc(htmlString) {
      const match = htmlString.match(/src="([^"]*)"/);
      return match ? match[1] : "No match found";
    },
    jumpTo(index) {
      if (index === 0) {
        // this.jumpToPolicies();
        this.jumpToContentList("主题活动");
      } else {
        this.jumpToContentList("分会动态");
      }
    },
    //数据处理
    handleMedicine() {
      const slices = {
        projectList: 5,
        technologyList: 5,
        learnList: 10,
        noticeList: 10,
        policyList: 10,
        headlineList: 6,
        announcementList: 6,
      };

      Object.keys(slices).forEach((key) => {
        this[key] = this[key] ? this[key].slice(0, slices[key]) : this[key];
      });

      this.medicineLeft = this.medicineList.slice(0, 6);
      // 特殊处理medicineRight，因为它的截取起始不是0
      this.medicineRight = this.medicineList.slice(6, 12);
    },

    // 时间日期处理 MM-DD
    formatDate(dateString) {
      const dateStringIos = dateString.replace(/-/g, "/");
      const date = new Date(dateStringIos);
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${month}-${day}`;
    },
    // 横向跑马灯方法
    setPicCarouse(num) {
      getAllCarousel();

      function getAllCarousel() {
        let hCarousel = $(".h_carousel"); // 跑马灯组件
        try {
          for (let i = 0; i < hCarousel.length; i++) {
            initCarousel({
              id: $(hCarousel[i]).attr("id"),
              dom: $("#" + $(hCarousel[i]).attr("id")),
            });
          }
        } catch (e) {}
      }

      /**
       * 初始化参数
       */
      function initCarousel({ id, dom }) {
        let option = {
          id: id,
          dom: dom,
          hFlag: 0,
          hTime: 0,
          hPhotoBox: $("#" + id + " .h_stage .h_photo_box"), // 获取表演队伍
          changeWidth: dom.attr("changeWidth"), // 获取每次运动像素
          hStageWidth: $("#" + id + " .h_stage")
            .css("width")
            .substr(0, $("#" + id + " .h_stage").css("width").length - 2), // 获取舞台宽度
          hPhotoQuantity: $("#" + id + " .h_stage .h_photo_box .h_photo")
            .length, // 获取上台成员数量
          hPhotoHtml: dom.find(".h_photo"),
        };

        // 这是设置跑马灯时间的
        const hTimeList = $(
          "#" + id + " .h_stage .h_photo_box .h_photo .h_time"
        );
        for (let i = 0; i < hTimeList.length; i++) {
          if (!$(hTimeList[i]).html()) {
            $(hTimeList[i]).css("display", "none");
          }
        }

        setPhoto(0, 4, option);
        setTimedTasks(option);
      }

      function setTimedTasks(option) {
        $("html")
          .bind("f_carousel" + option.id, function () {
            if (num == 2) {
              clearInterval(this.timer);
              option.hTime = 0;
            } else {
              this.timer = setInterval(function () {
                if (option.hFlag >= option.hPhotoQuantity - 1) {
                  option.hFlag = 0;
                } else {
                  option.hFlag++;
                }
                // setSelected();
                carouselAnimate(700, option);
              }, 5000);
              option.hTime = this.timer;
            }
          })
          .trigger("f_carousel" + option.id); // trigger("方法名")表示执行方法名为入参的方法
      }

      function carouselAnimate(options, option) {
        // 绑定一个动画事件
        // 第一个入参是表示动画执行的参与者以及变化结果
        // 第二个入参是执行时间为1000毫秒
        // 第三个入参是回调方法  代表动画执行完成之后需要执行的方法
        setBackstageWaiting(option);
        option.hPhotoBox.animate(
          { right: option.changeWidth + "px" },
          options,
          function () {
            option.dom.find(".h_photo_box").children().first().remove();
            // hCarouselMap.get('h_carousel').find('.h_photo_box').children().first().remove();
            option.hPhotoBox.css("right", "0px");
          }
        );
      }

      function setPhoto(flag, capacity, option) {
        let html = "";
        let count = 0;
        while (count < capacity) {
          html += $(option.hPhotoHtml[flag]).prop("outerHTML");
          if (flag >= option.hPhotoQuantity - 1) {
            flag = 0;
          } else {
            flag++;
          }
          count++;
        }
        option.dom.find(".h_photo_box").html(html);
      }

      // 设置后台
      function setBackstageWaiting(option) {
        let f = option.hFlag + 3;
        f = setF(f, option);
        option.dom
          .find(".h_photo_box")
          .append($(option.hPhotoHtml[f]).prop("outerHTML"));
      }

      function setF(f, option) {
        if (f > option.hPhotoQuantity - 1) {
          f = f - option.hPhotoQuantity;
          return setF(f, option);
        } else {
          return f;
        }
      }
    },

    changeIndex(index, id, item) {
      this.headMore = item;
      // console.log({id});
      // this.$router.push(`/contentList?id=${id}`)
      // 点击同一项时重置索引，否则设置为新的索引
      this.isClickIndex = this.isClickIndex === index ? 0 : index;

      // 映射项名称到相应的数据列表
      const itemMap = {
        头条信息: this.headlineList,
        公告声明: this.announcementList,
      };

      // 更新 headAnnoun 为对应的数据列表
      this.headAnnoun = itemMap[item] || []; // 如果item不匹配，则默认为空数组
    },
    changeLearn(index, id, item) {
      this.learnMore = item;
      // console.log({id});
      // this.$router.push(`/contentList?id=${id}`)
      // 点击同一项时重置索引，否则设置为新的索引
      this.isLearnIndex = this.isLearnIndex === index ? 0 : index;
      // 映射项名称到相应的数据列表
      const itemMap = {
        学会动态: this.learnList,
        通知公告: this.noticeList,
      };

      // 更新 headAnnoun 为对应的数据列表
      this.learnoticeList = itemMap[item] || []; // 如果item不匹配，则默认为空数组
    },
    changePolicies(index, id, item) {
      this.policiesMore = item;
      // console.log({id});
      // this.$router.push(`/contentList?id=${id}`)
      // 点击同一项时重置索引，否则设置为新的索引
      this.isPoliciesIndex = this.isPoliciesIndex === index ? 0 : index;
      // 映射项名称到相应的数据列表
      const itemMap = {
        // 党建政策: this.policyList,
        主题活动: this.mainactivity,
        分会动态: this.meeting,
      };

      // 更新 headAnnoun 为对应的数据列表
      this.policyList2 = itemMap[item] || []; // 如果item不匹配，则默认为空数组
      console.log(this.policyList2);
    },
    carouselChange(val) {
      this.carouselIndex = val;
    },
    carouselClick(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    jumpToContentPic(e) {
      // console.log(e,e.target.id,'tupia');
      if (!e.target.id) return;
      this.$router.push(`/content?id=${e.target.id}`);
    },
    jumpToContent(id) {
      this.$router.push(`/content?id=${id}`);
    },
    async jumpToContentList(more) {
      const { data } = await getClassifyId({ name: more });
      const id = data.id;
      // console.log({ id });
      this.$router.push(`/contentList?classifyId=${id}&name=${more}`);
    },
    jumpToNewContentList() {
      const url = "/#/contentList?classifyId=10&name=课题资讯";
      window.open(url);
    },
    jumpToVedio(e) {
      // console.log(e, e.target.id, "shipin");
      if (!e.target.id) return;
      const url = `/#/videoContent?id=${e.target.id}`;
      window.open(url);
    },
    jumpToSetproject() {
      this.$router.push("/setproject");
    },
    jumpToTechnology() {
      this.$router.push("/technology");
    },
    jumpToPolicies() {
      this.$router.push("/policies");
    },
  },
};
</script>

<style lang="scss" scoped>
// @media screen and (min-width: 1290px) {
//   .layout-container {
//     .app-main {
//       background: url("../assets/images/homebackground.jpg") !important;
//       background-size: 100% 100% !important;

//       padding-top: 160px !important;
//       padding-bottom: 190px !important;
//     }
//   }
// }
.more11:hover {
  cursor: pointer;
}
.isshow {
  display: none;
}
.isshow2 {
  display: block;
}
.active {
  padding-bottom: 44px;
  line-height: 35px;
  font-size: 32px;
  color: #0f48a3;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid #748693;
}
.title {
  display: flex;
  justify-content: space-between;
}

.more {
  float: right;
  margin: 0 15px;
  font-size: 16px;
  cursor: pointer;
}

.hy_notice .content .row .text {
  float: left;
  width: 260px;
  cursor: pointer;
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 内容超出宽度时隐藏超出部分的内容 */
  text-overflow: ellipsis; /* 当对象内文本溢出时显示省略标记(...) ；需与overflow:hidden;一起使用。*/
}

.hy_notice .content .row {
  display: block;
}
@media screen and (min-width: 1290px) {
  #hy-body {
    background: url("../assets/images/homebackground.jpg") no-repeat;
    background-size: 100% 100%;

    padding-top: 160px;
    padding-bottom: 190px;
  }
}
// #hy-body .body-content {
//    background-image: url("@/assets/images/main-bg.png");
//   background-size: 100% 100%;
//   background-repeat: no-repeat;
//   padding: 100px;
//   background-color: transparent !important;
// }
</style>

<!-- <style lang="scss" scoped>
#hy-body {
  // background-image: url("@/assets/images/homebackground.jpg");
  //  background-image: url("@/assets/images/main-bg.png");
  // background-size: 100% 100%;
  // padding-top: 140px !important;
  // padding-bottom: 160px !important;
}
.body-content {
  // opacity: 0;
  background-color: transparent !important;
}
</style> -->
