import Vue from 'vue'
import Vuex from 'vuex'
import { fetchClassifyId, fetchListByClassifyId } from '@/utils/home';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    allPageData: [], // 用于存储获取到的数据

  },
  getters: {
  },
  mutations: {
    setallPageData(state, data) {
      state.allPageData = data;
    },

    // 其他mutations...
  },
  actions: {
    async fetchAndStoreData({ commit }, { categoryName }) {
      try {
        const categoryId = await fetchClassifyId(categoryName);
        const listData = await fetchListByClassifyId(categoryId);
        commit('setallPageData', listData);
      } catch (error) {
        console.error('Error fetching data:', error);
        // 可以在这里处理错误，比如通过commit一个状态或者是显示错误信息
      }
    },
    // 其他actions...
  },
  modules: {
  }
})
