import { getClassifyId, getList } from '@/api/home';

export async function fetchClassifyId(categoryName) {
    try {
        const response = await getClassifyId({ name: categoryName });
        const id = response.data ? response.data.id : '';
        return id;
    } catch (error) {
        console.error('Error getting classify ID:', error);
        throw error; // 抛出错误，让调用者处理
    }
}

export async function fetchListByClassifyId(classifyId) {
    try {
        const response = await getList({ classifyId });
        const allData = response.data.indexVOS ?? [];
        return allData;
    } catch (error) {
        console.error('Error getting list:', error);
        throw error; // 抛出错误，让调用者处理
    }
}