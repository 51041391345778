import request from '@/utils/request'

// 官网头部菜单id接口
export function getClassifyId(query) {
    return request({
        url: '/index/getClassifyId',
        method: 'get',
        params:query
    })
}

// 官网列表接口
export function getList(query) {
    return request({
        url: '/index/list',
        method: 'get',
        params: query
    })
}

// 学会概况-分支机构列表接口
export function branchGetList(data) {
    return request({
        url: '/system/branch/list',
        method: 'post',
        data
    })
}